import React from 'react';
import { Link } from "react-router-dom";
import FormatMeetingTime from '../../utils/FormatMeetingTime';

class List extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      meetings: [],
      sort: '',
      up: true
    }
  }

  _sort = (type, e) => {
    let reverse = false;
    if(this.state.sort === type){
      reverse = !this.state.up
    }
    this.props.meetings.sort((a,b) => {
      let nameA = ''
      let nameB = ''
      if(type === 'time'){
        // let daysofWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        nameA = a['day'] + a[type];
        nameB = b['day'] + b[type];

      } else {
        nameA = a[type].toUpperCase();
        nameB = b[type].toUpperCase();
      }
      
      if(nameA < nameB){
        if(reverse)
          return 1;
        else 
          return -1;
      } 
      if(nameA > nameB)
        if(reverse)
          return -1;
        else
          return 1;
      
      return 0;
    });
    this.setState({
      sort: type,
      up: reverse
    })
  }

  _sortClass = (value) => {
    if(this.state.sort === value) {
      if(this.state.up)
        return 'sorted-up'
      else
        return 'sorted'
    }
  }

  _getLocation = (status, location) => {

    let _location = '';

    if(status !== 'virtual'){
      _location = location
    }

    return _location;

  };

  _getAddress = (status, address, slug) => {
    let _address = ''
    if(status === 'virtual'){
      _address = 
      <Link to={{
        pathname: `${slug}`
        }}>
      <button className="button is-info">
        <span className="icon">
          <i className="fas fa-video">&nbsp;</i>
        </span>
        <span>Zoom</span>
      </button>
      </Link>;
    } else if(status === 'hybrid'){
      _address = 
      <React.Fragment>
        <p className="buttons">
        <Link to={{
        pathname: `${slug}`
        }}>
        <button className="button is-success">
          <span className="icon">
            <i className="fas fa-map-marker-alt">&nbsp;</i>
          </span>
          <span>{address}</span>
        </button>
        </Link>
        <Link to={{
        pathname: `${slug}`
        }}>
        <button className="button is-info">
          <span className="icon">
            <i className="fas fa-video">&nbsp;</i>
          </span>
          <span>Zoom</span>
        </button>
        </Link>
        </p>
      </React.Fragment>; 
    } else {
      _address = 
      <Link to={{
        pathname: `${slug}`
        }}>
      <button className="button is-success">
        <span className="icon">
          <i className="fas fa-map-marker-alt"></i>
        </span>
        <span>{address}</span>
      </button>
      </Link>;
    }
    return _address;
  }

  render(){
    return (
      <table className="table is-striped is-fullwidth">
        <thead className="is-hidden-mobile">
          <tr>
            <th className={this._sortClass('time')} onClick={(e) => this._sort('time',e)}>Time</th>
            <th className={this._sortClass('slug')} onClick={(e) => this._sort('slug',e)}>Meeting</th>
            <th>Location / Group</th>
            <th>Address / Platform</th>
            <th className={this._sortClass('region')} onClick={(e) => this._sort('region',e)}>Region</th>
          </tr>
        </thead>
        <tbody>
          {this.props.meetings.map((meeting, i) =>
              <tr key={i}>
              <td className="time">{FormatMeetingTime(meeting.day, meeting.time)}</td>
              <td className="meeting">
                <Link to={{
                  pathname: `${meeting.slug}`
                  }}>{meeting.name}</Link>
              </td>
              <td>{this._getLocation(meeting.status, meeting.location)}</td>
              <td className="address">{this._getAddress(meeting.status, meeting.address, meeting.slug)}</td>
              <td className="type">
                {meeting.region}
              </td>
            </tr>             
          )}
        </tbody>
      </table>
    )
  }
}
export default List;