import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Modal from "../Modal/Modal";
// import eventdata from "../Events/events-data";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "",
      modalContent: "",
      modalShow: false,
      upcomingMeetings: [],
      allEvents : [],
      upcomingEvents: []
    };
  }

  componentDidMount() {
    fetch('/data/meetings-data.json'
    ,{
      headers : {
        'Context-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(meetingJson => {
        //remove closed meetings
        meetingJson = meetingJson.filter(meeting => meeting.status !== "closed");
        //sort by date
        meetingJson.sort((a,b) => {
          let nameA = ''
          let nameB = ''
          // let daysofWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          nameA = a['day'] + a['time'];
          nameB = b['day'] + b['time'];

          if(nameA < nameB)
            return -1;
          if(nameA > nameB)
            return 1;
          
          return 0;
        });

        let upcomingMeetings = this._getUpcomingMeetings(meetingJson);
        

        //sort meeting by date
        upcomingMeetings.sort((a, b) => {
          let dateA, dateB;
          dateA = a["meetingTime"];
          dateB = b["meetingTime"];
          
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;

          return 0;
        });

        this.setState({
          upcomingMeetings
        });
      });

      //get events data
      fetch('/data/events-data.json'
        ,{
          headers: {
            'Context-Type' : 'application/json'
          }
        })
      .then(response => {
        return response.json();
      })
      .then(eventsJson => {

        //sort by date
        let sortedEvents = this._getUpcomingEvents(eventsJson).sort((a,b) => {
          let dateA = ''
          let dateB = ''
          //either DateTimeStart or Date
          if(a['DateTimeStart'])
            dateA = new Date(a['DateTimeStart']);
          else
            dateA = new Date(a['Date']);

          if(b['DateTimeStart'])
            dateB = new Date(b['DateTimeStart']);
          else
            dateB = new Date(b['Date']);

          if(dateA < dateB)
            return -1;
          if(dateA > dateB)
            return 1;
          
          return 0;
        });

        this.setState({
          upcomingEvents: sortedEvents
        })

      })
  }

  _getUpcomingMeetings = (meetings) => {
    let now =  moment().add(-1, 'h');
    let next24 = moment().add(24, 'h');
    
    let upcomingMeetings = meetings.filter((meeting, index) => {
      let hour = meeting.time.substring(0,meeting.time.indexOf(':'));
      if(meeting.time.substring(meeting.time.length-2, meeting.time.length) === 'pm' && hour !== '12'){
        hour = parseInt(hour) + 12;
      }

      let min = meeting.time.substring(meeting.time.indexOf(':')+1, meeting.time.indexOf(':') + 3);
      let meetingTime = moment().set({'day': meeting.day, 'hour': hour, 'minute': min});

      meetings[index].meetingTime = meetingTime;

      return meetingTime.isBetween(now, next24) !== false;
    });

    return upcomingMeetings;
  }

  _getUpcomingEvents = (events) => {

    let now =  moment();
    let nextFive = moment().add(30, 'd');

    let upcomingEvents = events.filter((event) => {


      //search DateTimeStart or Date
      if(event.DateTimeStart){
        if(event.DateTimeEnd){
          return moment(event.DateTimeEnd).isBetween(now, nextFive) !== false;
        }else {
          return moment(event.DateTimeStart).isBetween(now, nextFive) !== false;
        }
      }
      else
        return moment(event.Date).isBetween(now, nextFive) !== false;

    });

    return upcomingEvents;

  };

  _setModal = (img, type, e) => {
    e.preventDefault();
    this.setState({
      modalType: type,
      modalContent: img,
    });
    this._toggleModal();
  };

  _toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalShow;
      return { modalShow: newState };
    });
  };

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">
                    Welcome to Cocaine Anonymous New York
                  </p>
                  <p>
                  Cocaine Anonymous is a fellowship of men and women who share their experience, strength and hope with each other that they may solve their common problem and help others to recover from their addiction.
                  </p>
                  <p>
                  The only requirement for membership is a desire to stop using cocaine and all other mind‐ altering substances.There are no dues or fees for membership; we are fully self‐ supporting through our own contributions.
                  </p>
                  <p>
                  We are not allied with any sect, denomination, politics, organization or institution.We do not wish to engage in any controversy, and we neither endorse nor oppose any causes. 
                  </p>
                  <p>Our primary purpose is to stay free from cocaine and all other mind‐ altering substances and to help others achieve the same freedom.</p>
                  <p>We use the Twelve‐ Step recovery Program because it has already been proven that the Twelve‐ Step Recovery Program works.</p>
                  <div className="divider"></div>
                  <p>
                    I'm concerned about my drinking or drug use.<br></br>
                    <Link to="/literature/cocaine_anonymous_self_test">Click here</Link> if you are looking for help with a drink or drug problem.
                  </p>
                  <div className="divider"></div>
                  <p className="is-size-5">
                    Find a <Link to="/meetings">meeting</Link> or call our <a href="tel:212-262-2463">helpline</a>
                  </p>
                </article>
              </div>
              <div className="tile is-parent content">
                <article className="tile is-child box">
                  <p className="subtitle">
                    <u>Upcoming Meetings</u>:
                  </p>
                  {this.state.upcomingMeetings.map(
                    (meeting, i) =>
                        <p key={i}>
                          {moment(meeting.meetingTime).format('ddd, h:mm A')} (EST) - &nbsp;
                        <Link to={'/meetings/' + meeting.slug} >{meeting.name}</Link> [{meeting.types.length > 0
                      ? meeting.types.map((type, i) => (
                          <React.Fragment key={i}>
                            {type}
                            {i !== meeting.types.length - 1
                              ? ","
                              : ""}
                          </React.Fragment>
                        ))
                      : ""}]
                        </p>
                  )}
                  <p className="is-size-7">
                  <i>[BE-Beginners, BB-Big Book, CD-Closed Discussion, M-Men Only, W-Women Only, OD-Open Discussion [public invited], S-Step Meeting, TC-Topic, 
                  CP-Candlelight Promises]</i>
                  </p>
                  <p>
                    Please see <Link to="/meetings">meeting page</Link> for
                    details and all meetings.
                  </p>
                  {this.state.upcomingEvents.length > 0 &&
                  <React.Fragment>
                  <p className="subtitle">
                    <u>Upcoming Events:</u>
                  </p>
                  <ul>
                  {this.state.upcomingEvents.map(
                    (event, i) =>
                      <li key={i}>
                        {moment(event.DateTimeStart).format('LLLL')} ({moment().tz(moment.tz.guess()).format('z')}) - 
                        {/* <a
                            href="0"
                            onClick={(e) =>
                              this._setModal(
                                event.LocationLink,
                                "leaving",
                                e
                              )
                            }
                          > {event.Name}</a> */}
                          &nbsp; {event.Name}
                      </li>
                  )}  
                  </ul>
                  <p>
                  </p>
                  <p><br></br>
                    Please see <Link to="/events">events page</Link> for
                    details and all events.
                  </p>
                  </React.Fragment>
                  }
                  <p>
                  <b>New CA meeting:</b><br></br>
                  Circles of Strength group<br></br> 
                  Tuesday at 7:30pm<br></br> 
                  Castleton Hill Moravian Church<br></br> 
                  1657 Victory Blvd. Staten Island<br></br>
                  Speaker Meeting
                  </p>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <article className="tile is-child box">
                  <p className="subtitle">
                    CANY Area
                  </p>
                  <p>The Area serves the Fellowship by distributing chips and literature, handling financial contributions of the Fellowship for that Area, and communicating with the C.A. World Service Office. The primary function of the Area is to serve the common needs of its District Members and to facilitate unification. </p>
                  <p>
                    Are you a GSR? All CA meetings should have a elected GSR.</p>
                  <p><br></br>
                    Area meets the 2nd Wednesday of each month at 7:00 PM
                    online. <br /><br />
                    <a
                      href="0"
                      onClick={(e) =>
                        this._setModal(
                          "https://us02web.zoom.us/j/82360875449?pwd=enVIWEVIakFlOHVEZkpzdFVKbFR0UT09",
                          "leaving",
                          e
                        )
                      }
                    >
                      Join Zoom Meeting
                    </a>{" "}
                    <br />
                    Meeting ID: 823 6087 5449 <br />
                    Password: 937280
                  </p>
                </article>
              </div>
              <div className="tile is-parent">
              <article className="tile is-child box">
                <p className="subtitle">
                    Attention Virtual Meeting Hosts: A suggested preamble for
                    Virtual Meetings
                  </p>
                  <p>
                    “Due to the technologies being used for this meeting, a
                    participant’s anonymity, or the anonymity of others
                    mentioned, could be compromised at a public level.
                  </p>
                  <p>
                    The individual participants and our group as a whole have
                    the responsibility to consider the potential implications,
                    and our level of participation in this meeting.
                  </p>
                  <p>
                    Our group conscience reminds us that Anonymity is the
                    Spiritual Foundation of all our Traditions. Additionally, in
                    the spirit of Tradition Six, C.A. is not allied with any
                    sect, denomination, politics, organization or institution
                    and as such does not endorse and is not affiliated with any
                    of the specific technology providers utilized to facilitate
                    this meeting.”
                  </p>
                </article>
              </div>
            </div>
            <div className="tile is-ancestor">
              
            
            </div>
          </div>
        </section>
        <Modal
          type={this.state.modalType}
          content={this.state.modalContent}
          closeModal={this._toggleModal}
          openLink={this._openLink}
          modalState={this.state.modalShow}
        />
      </React.Fragment>
    );
  }
}

export default Home;
