import React from 'react';
import List from './List';
import Modal from '../Modal/Modal';
import './Meetings.css'
class Meetings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      meetings: [],
      init_meetings: [],
      view : 'list',
      day: 'Any Day',
      region: 'Any Region',
      textSearch: '',
      modalType: '',
      modalContent: '',
      modalShow: false
    };
  }

  componentDidMount(){
    fetch('/data/meetings-data.json'
    ,{
      headers : {
        'Context-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(meetingJson => {
        //remove closed meetings
        meetingJson = meetingJson.filter(meeting => meeting.status !== "closed");
        //sort by date
        meetingJson.sort((a,b) => {
          let nameA = ''
          let nameB = ''
          // let daysofWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          nameA = a['day'] + a['time'];
          nameB = b['day'] + b['time'];

          if(nameA < nameB)
            return -1;
          if(nameA > nameB)
            return 1;
          
          return 0;
        });

        this.setState({
          meetings: meetingJson,
          init_meetings: meetingJson
        })
      });

    window.scrollTo(0, 0);
  }

  _searchMeetings = () => {
    // debugger
    let fullTextSearch = this.state.textSearch;
    let daySearch = this.state.day;
    let regionSearch = this.state.region !== 'Any Region' ? this.state.region : '';

    let updatedMeetings = this.state.init_meetings;

    updatedMeetings = updatedMeetings.filter(meeting => {
      //search Meeting & Location
      return meeting.name.toLowerCase().search(fullTextSearch.toLowerCase()) !== -1 &&
             meeting.region.toLowerCase().search(regionSearch.toLowerCase()) !== -1 &&
             (meeting.day === Number(daySearch) || daySearch === 'Any Day')
    });

    console.log(updatedMeetings)
    this.setState({meetings: updatedMeetings});
  };

  _setView = (type, e) => {
    e.preventDefault();
    if(type === 'list') this._searchMeetings();
    this.setState({view : type});

  }

  _handleChange = (type, e) => {
    e.preventDefault();
    this.setState({[type]: e.target.value},
      () => this._searchMeetings());
  };

  _setModal = (img, type ,e) => {
    e.preventDefault();
    this.setState({ 
        modalType: type,
        modalContent: img,
    })
    this._toggleModal();
  }

  _toggleModal = () => {
    this.setState((prev, props)=> {
      const newState = !prev.modalShow;
      return { modalShow : newState}
    });
  }

  _openLink = () => {
    this._toggleModal();
    window.open(this.state.modalContent, "_blank");
  }

  render() {
    return (
      <React.Fragment>
      <section className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="field">
                  <p className="control has-icons-left">
                    <input value={this.state.searchText} onChange={(e) => this._handleChange('textSearch',e)} className="input" type="text" placeholder="Search"/>
                    <span className="icon is-left">
                      <i className="fas fa-search"></i>
                    </span>
                  </p>
                </div>
              </div>
              <div className="level-item">
                <div className="field">
                  <div className="control has-icons-left">
                    <div className="select">
                      <select value={this.state.day} onChange={(e) => this._handleChange('day', e)}>
                        <option>Any Day</option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                        <option value="0">Sunday</option>
                      </select>
                    </div>
                    <div className="icon is-small is-left">
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="level-item">
                <div className="field">
                  <div className="control has-icons-left">
                    <div className="select">
                      <select value={this.state.Region} onChange={(e) => this._handleChange('region', e)}>
                        <option>Any Region</option>
                        <option>Manhattan</option>
                        <option>Brooklyn</option>
                        <option>Queens</option>
                        <option>Long Island</option>
                        <option>Buffalo</option>
                        <option>Westchester</option>
                      </select>
                    </div>
                    <div className="icon is-small is-left">
                      <i className="fas fa-globe"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
      <section className="section">
        <div className="container">
          <List match={this.props.match} meetings={this.state.meetings}></List>
        <div className="box has-text-centered">
        B-Beginners, BB-Big Book, CD-Closed Discussion, M-Men Only, W-Women Only, OD-Open Discussion [public invited], S-Step Meeting, T-Topic, 
        CP-Candlelight Promises, LGBTQIA+ - Lesbian, gay, bisexual, and transgender,  Anniversary Meetings celebrate milestones of members (all are welcome)
        </div>
        <div className="box has-text-centered">
        </div>
        <div className="box">
          For meetings in NJ please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('https://canj.org/','leaving', e)}>CA New Jersey</a><br></br>
          For meetings in Northern California please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('https://canorcal.org/','leaving', e)}>CA Northern California</a><br></br>
          For meetings in Florida please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('http://caflorida.org/','leaving', e)}>CA Florida</a><br></br>
          For meetings in Southern Ontario please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('https://ca-on.org/','leaving', e)}>CA Southern Ontario</a><br></br>
          For meetings in UK please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('https://cocaineanonymous.org.uk/','leaving', e)}>CA UK</a><br></br>
          For CA Online please visit : <a href="0" alt="leaving" onClick={(e) => this._setModal('https://www.ca-online.org/','leaving', e)}>CA Online</a><br></br>
        </div>
        </div>
      </section>
          <Modal type={this.state.modalType} content={this.state.modalContent} closeModal={this._toggleModal} openLink={this._openLink} modalState={this.state.modalShow} />
      </React.Fragment>
    );
  }
}


export default Meetings;